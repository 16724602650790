<template>
  <div class="dashboard">
    <Card>
      <template #header>
        <img alt="Messenger Pigeon" :src="MPLogo" />
      </template>
      <template #content>
        <form @submit.prevent="submitForm">
          <span class="p-float-label">
            <InputText
              id="document-name"
              type="text"
              v-model.trim="documentName"
            />
            <label for="document-name">Session ID</label>
          </span>
          <Button label="Go to session" @click="goToDocument" />
        </form>
      </template>
    </Card>
  </div>
</template>

<script>
import MPLogo from "../assets/images/logos/MP-Habitat-Logo-Dark.svg";

export default {
  data() {
    return {
      MPLogo,
      documentName: "",
    };
  },
  created() {
    document.title = "Messenger Pigeon";
  },
  methods: {
    goToDocument() {
      this.$router.push("/documents/" + this.documentName);
    },
  },
};
</script>
<style lang="scss">
.dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .p-card {
    padding: 2.4rem 2.4rem 1.6rem;
    border-radius: 0.8rem;
  }

  .p-card-header {
    line-height: 0;
  }

  .p-card-body {
    padding: 0;
    margin-top: 2.4rem;

    .p-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    form {
      width: 21rem;
      display: flex;
      align-items: center;
      flex-direction: column;

      button {
        width: 55%;
        margin-top: 1.2rem;
        font-size: 1.2rem;
        background-color: map-get($colours, accent-purple-3);
      }

      .p-float-label {
        width: 100%;
        font-size: 1.2rem;

        input {
          width: 100%;
          padding: 0.8rem 1rem;
        }
      }
    }
  }
}
</style>
