<template>
  <button
    :class="[
      'rounded-square-button',
      primary ? 'primary' : '',
      dark ? 'dark' : '',
      smallImg ? 'small-img' : '',
      border ? 'border' : '',
      accent ? 'accent' : '',
      transparentLight ? 'transparent border-light' : '',
      transparentDark ? 'transparent border-dark' : '',
      noShadow ? 'no-shadow' : '',
      small ? 'small' : '',
      large ? 'large' : '',
    ]"
    :disabled="disabled"
    @click="onClick"
    :active="active"
  >
    <div
      v-if="image"
      :style="backgroundStyles(image)"
      class="image"
      :class="[
        tallestImg ? 'tallest-img' : '',
        tallImg ? 'tall-img' : '',
        shiftRight ? 'shift-right' : '',
        accentImg ? 'accent-img' : '',
        accent ? 'accent' : '',
        transparentLight ? 'light-img' : '',
      ]"
    />
    <span v-else>{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    active: Boolean,
    disabled: Boolean,
    primary: Boolean,
    onClick: Function,
    image: String,
    imageAlt: String,
    text: String,
    dark: Boolean,
    tallImg: Boolean,
    tallestImg: Boolean,
    smallImg: Boolean,
    shiftRight: Boolean,
    accentImg: Boolean,
    border: Boolean,
    accent: Boolean,
    transparentLight: Boolean,
    transparentDark: Boolean,
    noShadow: Boolean,
    small: Boolean,
    large: Boolean,
  },

  methods: {
    backgroundStyles(image) {
      return {
        mask: `url(${image}) no-repeat center`,
        "mask-size": "contain",
        "-webkit-mask": `url(${image}) no-repeat center`,
        "-webkit-mask-size": "contain",
      };
    },
  },
};
</script>

<style lang="scss">
.rounded-square-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  min-height: 4rem;
  max-height: 4rem;
  width: 4rem;
  min-width: 4rem;
  max-width: 4rem;
  border-radius: 0.8rem;
  font-size: 1rem;
  border: none;
  background-color: map-get($component-colours, button-colour-secondary);
  color: map-get($component-colours, button-font-colour-secondary);
  padding: 0.6rem;
  box-shadow: 1px -1px 2px rgba(0, 0, 0, 0.1), -1px 1px 2px rgba(0, 0, 0, 0.1),
    1px 1px 2px rgba(0, 0, 0, 0.1), -1px -1px 2px rgba(0, 0, 0, 0.1);

  &[disabled] {
    background-color: map-get($component-colours, button-colour-disabled);
    color: map-get($component-colours, button-font-colour-disabled);

    .image {
      &.light-img {
        background-color: map-get(
          $component-colours,
          button-image-colour-disabled
        );
      }
    }
  }

  &:hover,
  &:active {
    &:not([disabled]) {
      filter: brightness(80%);
      cursor: pointer;
    }
  }

  &.small {
    height: 3rem;
    min-height: 3rem;
    max-height: 3rem;
    width: 3rem;
    min-width: 3rem;
    max-width: 3rem;
  }

  &.large {
    height: 6rem;
    min-height: 6rem;
    max-height: 6rem;
    width: 6rem;
    min-width: 6rem;
    max-width: 6rem;

    &.small-img {
      padding: 1.8rem;
    }
  }

  &.small-img {
    padding: 1.2rem;
  }

  &.border {
    border: 1px solid map-get($component-colours, button-image-colour-primary);
    box-shadow: none;
  }

  &.primary {
    background-color: map-get($component-colours, button-colour-primary);
    color: map-get($component-colours, button-font-colour-primary);
  }

  &.dark {
    background-color: map-get($component-colours, button-colour-dark);
    color: map-get($component-colours, button-font-colour-primary);
  }

  &.accent {
    background-color: map-get($component-colours, button-colour-accent);
  }

  &.transparent {
    background-color: transparent;
  }

  &.border-light {
    border: 1px solid map-get($component-colours, button-image-colour-alternate);

    &:hover,
    &:active {
      &:not([disabled]) {
        filter: brightness(150%);
      }
    }

    &:active {
      background-color: map-get(
        $component-colours,
        button-image-colour-alternate
      );
    }
  }

  &.border-dark {
    border: 1px solid map-get($component-colours, button-image-colour-primary);

    &:hover,
    &:active {
      &:not([disabled]) {
        filter: brightness(300%);
      }
    }
  }

  &.no-shadow {
    box-shadow: none;
  }

  .image {
    height: 100%;
    width: 100%;
    background-color: map-get($component-colours, button-image-colour-primary);

    &.tallest-img {
      max-height: 100%;
    }

    &.shift-right {
      margin-left: 0.4rem;
    }

    &.accent-img {
      background-color: map-get($component-colours, button-image-colour-accent);
    }

    &.accent {
      background-color: map-get($component-colours, button-font-colour-accent);
    }

    &.light-img {
      background-color: map-get(
        $component-colours,
        button-image-colour-alternate
      );
    }
  }
}
</style>
