<template>
  <button
    v-if="icon"
    class="icon-button"
    :class="[
      background ? 'background' : '',
      dark ? 'dark' : '',
      addPadding ? 'add-padding' : '',
      xsmall ? 'xsmall' : '',
      small ? 'small' : '',
      big ? 'big' : '',
      inheritSize ? 'inherit-size' : '',
    ]"
    :onclick="onclick"
  >
    <img
      :src="icon"
      alt="alt"
      :class="[
        rotated ? 'rotated' : '',
        xsmall ? 'xsmall' : '',
        big ? 'big' : '',
        inheritSize ? 'inherit-size' : '',
      ]"
    />
  </button>
</template>

<script>
export default {
  props: {
    icon: String,
    alt: String,
    onclick: Function,
    rotated: Boolean,
    xsmall: Boolean,
    small: Boolean,
    big: Boolean,
    addPadding: Boolean,
    background: Boolean,
    dark: Boolean,
    inheritSize: Boolean,
  },
};
</script>

<style lang="scss">
.icon-button {
  background: none;
  padding: 0;

  &[disabled] {
    filter: brightness(50%);
  }

  &.inherit-size {
    height: 100%;
  }

  &.add-padding {
    padding: 1.6rem;
  }

  &.background {
    background: map-get($component-colours, button-colour-secondary);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    img {
      height: 100%;
      width: 100%;
    }

    &.dark {
      background: map-get($component-colours, button-colour-dark);
    }

    &.xsmall {
      width: 2.4rem;
      height: 2.4rem;
    }

    &.small {
      width: 3.6rem;
      height: 3.6rem;
    }

    &.big {
      width: 4.8rem;
      height: 4.8rem;
    }
  }

  img {
    transition: transform 0.5s ease-in-out;
    display: block;
    width: 4rem;
    height: 4rem;

    &.inherit-size {
      width: 100%;
      height: 100%;
    }

    &.rotated {
      transform: rotate(-180deg);
    }

    &.xsmall {
      width: 2.4rem;
      height: 2.4rem;
    }

    &.big {
      width: 4.8rem;
      height: 4.8rem;
    }
  }
}
</style>
