import { createRouter, createWebHistory } from "vue-router";
import Document from "../views/Document.vue";
import Uhoh from "../views/Uhoh.vue";
import Dashboard from "../views/Dashboard.vue";
import Feedback from "../views/Feedback.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/documents/:document",
    name: "Document",
    component: Document,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Login.vue"),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/uhoh",
    name: "Uhoh",
    component: Uhoh,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
