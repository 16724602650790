<template>
  <div class="side-panel">
    <WelcomePanel
      v-if="activePanel === SidePanels.WELCOME"
      class="welcome-panel"
      :users="users"
      :currentUser="currentUser"
      :updateCurrentUser="updateCurrentUser"
      :clickClose="closeWelcomePanel"
    />
    <KeepAlive>
      <ChatList
        v-if="activePanel === SidePanels.CHAT"
        :users="users"
        :incrementNumUnreadMessages="incrementNumUnreadMessages"
        :clearNumUnreadMessages="clearNumUnreadMessages"
        :currentUser="currentUser"
        :updateCurrentUser="updateCurrentUser"
        :unreadMessages="unreadMessages"
        :closeSidePanel="closeSidePanel"
        :showChatMainMenu="showChatMainMenu"
        :setShowChatMainMenu="setShowChatMainMenu"
      />
    </KeepAlive>
    <SettingsPanel
      v-if="activePanel === SidePanels.SETTINGS"
      :closeSidePanel="closeSidePanel"
    />
  </div>
</template>

<script>
import WelcomePanel from "./Welcome/WelcomePanel";
import ChatList from "./Chat/ChatList";
import SettingsPanel from "./Settings/SettingsPanel";

export default {
  components: {
    WelcomePanel,
    ChatList,
    SettingsPanel,
  },

  props: {
    isOpen: Boolean,
    SidePanels: Object,
    activePanel: Number,
    incrementNumUnreadMessages: Function,
    clearNumUnreadMessages: Function,
    users: Array,
    currentUser: Object,
    updateCurrentUser: Function,
    unreadMessages: Map,
    closeSidePanel: Function,
    setActivePanel: Function,
    closeWelcomePanel: Function,
  },

  data() {
    return {
      showChatMainMenu: true,
    };
  },

  watch: {
    isOpen: function(newVal) {
      // When closing, want to start on the main menu for chat next time it's opened
      if (!newVal) {
        setTimeout(() => {
          this.showChatMainMenu = true;
        }, 400);
      }
    },
  },

  methods: {
    setShowChatMainMenu(bool) {
      this.showChatMainMenu = bool;
    },
  },
};
</script>

<style lang="scss">
.side-panel {
  background-color: map-get(
    $component-colours,
    side-panel-background-dark-mode
  );
  color: map-get($component-colours, side-panel-font-primary-dark-mode);
  min-width: $side-panel-width-in-rem + rem;
}
</style>
