<template>
  <OverlayPanel
    ref="op"
    :showCloseIcon="true"
    class="font-format-menu"
    :class="this.$store.state.lightModeActive ? 'light-mode-active' : ''"
  >
    <div :class="['menu-row', 'font-size-row']">
      <span :onClick="decrementFontSize" class="font-size-slider-left-label"
        >A</span
      >
      <Slider
        class="font-size-slider"
        v-model="fontSize"
        @change="changeFontSize(this.fontSize)"
        :step="this.SIZE_INCREMENT"
        :min="this.MIN_FONT_SIZE"
        :max="this.MAX_FONT_SIZE"
      />
      <span :onClick="incrementFontSize" class="font-size-slider-right-label"
        >A</span
      >
    </div>
    <div :class="['menu-row', 'colour-bold-row']">
      <div class="colour-options">
        <CustomRadioButton
          label="white"
          v-model="selectedColour"
          :value="this.FontColours.WHITE"
          :onClick="colourButtonClicked"
        />
        <CustomRadioButton
          label="black"
          v-model="selectedColour"
          :value="this.FontColours.BLACK"
          :onClick="colourButtonClicked"
        />
        <CustomRadioButton
          label="yellow"
          v-model="selectedColour"
          :value="this.FontColours.YELLOW"
          :onClick="colourButtonClicked"
        />
        <CustomRadioButton
          label="lime"
          v-model="selectedColour"
          :value="this.FontColours.LIME"
          :onClick="colourButtonClicked"
        />
        <CustomRadioButton
          label="blue"
          v-model="selectedColour"
          :value="this.FontColours.BLUE"
          :onClick="colourButtonClicked"
        />
        <CustomRadioButton
          label="turquoise"
          v-model="selectedColour"
          :value="this.FontColours.TURQUOISE"
          :onClick="colourButtonClicked"
        />
      </div>
      <button
        :class="[
          'bold-button',
          this.fontBold ? boldActive : '',
          this.$store.state.lightModeActive ? 'light-mode-active' : '',
        ]"
        :onClick="boldButtonClicked"
      >
        B
      </button>
    </div>
    <div :class="['menu-row', 'font-button']">
      <RadioButton
        id="averta"
        name="fontFamily"
        @change="changeFontFamily(this.fontFamily)"
        v-model="fontFamily"
        :value="this.AVERTA"
      />
      <label for="averta" :style="{ fontFamily: this.AVERTA }">Averta</label>
    </div>
    <div :class="['menu-row', 'font-button']">
      <RadioButton
        id="arial"
        name="fontFamily"
        @change="changeFontFamily(this.fontFamily)"
        v-model="fontFamily"
        :value="this.ARIAL"
      />
      <label for="arial" :style="{ fontFamily: this.ARIAL }">Arial</label>
    </div>
    <div :class="['menu-row', 'font-button']">
      <RadioButton
        id="openDyslexic"
        name="fontFamily"
        @change="changeFontFamily(this.fontFamily)"
        v-model="fontFamily"
        :value="this.OPEN_DYSLEXIC"
      />
      <label for="openDyslexic" :style="{ fontFamily: this.OPEN_DYSLEXIC }"
        >Open Dyslexic</label
      >
    </div>
    <div :class="['menu-row', 'font-button']">
      <RadioButton
        id="tahoma"
        name="fontFamily"
        @change="changeFontFamily(this.fontFamily)"
        v-model="fontFamily"
        :value="this.TAHOMA"
      />
      <label for="tahoma" :style="{ fontFamily: this.TAHOMA }">Tahoma</label>
    </div>
    <div :class="['menu-row', 'font-button']">
      <RadioButton
        id="comicSans"
        name="fontFamily"
        @change="changeFontFamily(this.fontFamily)"
        v-model="fontFamily"
        :value="this.COMIC_SANS"
      />
      <label for="comicSans" :style="{ fontFamily: this.COMIC_SANS }"
        >Comic Sans</label
      >
    </div>
    <div :class="['menu-row', 'font-button']">
      <RadioButton
        id="timesNewRoman"
        name="fontFamily"
        @change="changeFontFamily(this.fontFamily)"
        v-model="fontFamily"
        :value="this.TIMES_NEW_ROMAN"
      />
      <label for="timesNewRoman" :style="{ fontFamily: this.TIMES_NEW_ROMAN }"
        >Times New Roman</label
      >
    </div>
  </OverlayPanel>
</template>

<script>
import CustomRadioButton from "./CustomRadioButton.vue";

export default {
  components: {
    CustomRadioButton,
  },

  props: {
    userPreferences: Object,
    FontColours: Object,
    changeFontColour: Function,
    toggleFontBold: Function,
    changeFontFamily: Function,
    changeFontSize: Function,
    setClosed: Function,
    fontBold: Boolean,
  },

  data() {
    this.AVERTA = "Averta";
    this.ARIAL = "Arial";
    this.OPEN_DYSLEXIC = "Open Dyslexic";
    this.TAHOMA = "Tahoma";
    this.COMIC_SANS = "Comic Sans";
    this.TIMES_NEW_ROMAN = "Times New Roman";
    this.SIZE_INCREMENT = 4;
    this.MAX_FONT_SIZE = 42;
    this.MIN_FONT_SIZE = 10;

    this.DEFAULT_FONT_SIZE = 26;

    this.DEFAULT_FONT_FAMILY = this.AVERTA;

    return {
      boldActive: "bold-button-active",
      fontSize: this.userPreferences.fontSize
        ? parseFloat(this.userPreferences.fontSize.replace("rem", "")) * 10
        : this.DEFAULT_FONT_SIZE,
      fontFamily: this.userPreferences.fontFamily
        ? this.userPreferences.fontFamily
        : this.DEFAULT_FONT_FAMILY,
      selectedColour: this.userPreferences.fontColour
        ? this.userPreferences.fontColour
        : this.DEFAULT_FONT_COLOUR,
    };
  },

  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    },

    colourButtonClicked(event) {
      this.changeFontColour(event.target.value);
      this.selectedColour = event.target.value;
    },

    boldButtonClicked() {
      this.toggleFontBold();
    },

    incrementFontSize() {
      if (this.fontSize < this.MAX_FONT_SIZE) {
        this.fontSize += this.SIZE_INCREMENT;
        this.changeFontSize(this.fontSize);
      }
    },

    decrementFontSize() {
      if (this.fontSize > this.MIN_FONT_SIZE) {
        this.fontSize -= this.SIZE_INCREMENT;
        this.changeFontSize(this.fontSize);
      }
    },
  },
};
</script>

<style lang="scss">
.font-format-menu {
  background: map-get(
    $component-colours,
    font-format-menu-background-dark-mode
  );
  color: map-get($component-colours, font-format-menu-font-dark-mode);

  &.light-mode-active {
    background: map-get(
      $component-colours,
      font-format-menu-background-light-mode
    );
    color: map-get($component-colours, font-format-menu-font-light-mode);

    .p-overlaypanel-content {
      > *:not(:last-child) {
        border-bottom: 1px solid map-get($component-colours, border-dark);
      }
    }
  }

  .p-overlaypanel-content {
    padding: 0.8rem !important;

    > *:not(:first-child) {
      padding-top: 0.6rem;
    }

    > *:not(:last-child) {
      padding-bottom: 0.6rem;
      border-bottom: 1px solid map-get($component-colours, border-light);
    }

    .menu-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
    }

    .font-size-row {
      border-bottom: none;

      .p-slider-horizontal {
        flex: 1;
        padding: 3px;
      }
      .p-slider {
        border-radius: 10px;
      }
      .p-slider-range {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .p-slider-handle {
        border: white solid 1px;
      }
    }

    .font-size-slider-left-label {
      font-size: 1.2rem;
      margin-right: 1rem;
      cursor: pointer;
    }

    .font-size-slider-right-label {
      font-size: 2.2rem;
      margin-left: 1rem;
      line-height: 1.4rem;
      cursor: pointer;
    }

    .font-size-slider {
      cursor: pointer;
    }

    .colour-bold-row {
      display: flex;
      justify-content: space-between;

      .colour-options {
        > *:not(:first-child) {
          margin-left: 0.3rem;
        }

        > *:not(:last-child) {
          margin-right: 0.3rem;
        }
      }

      .bold-button {
        border: 0.2rem solid rgba(255, 255, 255, 0.4);
        border-radius: 25%;
        background: none;
        font-size: 2.3rem;
        padding: 0.6rem;
        padding-bottom: 0.2rem;
        margin-bottom: 0.5rem;
        margin-left: 1.3rem !important;
        margin-right: 0.5rem;
        line-height: 0.85;
        color: rgb(255, 255, 255);

        &.light-mode-active {
          border: 0.2rem solid rgba(0, 0, 0, 0.4);
          color: rgb(0, 0, 0);
        }
      }

      .bold-button:hover {
        border: 0.2rem solid rgba(255, 255, 255, 0.8);
        background-color: rgba(255, 255, 255, 0.1);

        &.light-mode-active {
          border: 0.2rem solid rgba(0, 0, 0, 0.8);
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      .bold-button-active {
        font-weight: bold;
        border: 0.2rem solid rgb(255, 255, 255);

        &.light-mode-active {
          border: 0.2rem solid rgb(0, 0, 0);
        }
      }
    }

    .font-button {
      display: flex;
      align-items: center;

      .p-radiobutton {
        width: 1.6rem;
        height: 1.6rem;

        .p-radiobutton-box {
          width: 1.6rem;
          height: 1.6rem;

          .p-radiobutton-icon {
            width: 0.8rem;
            height: 0.8rem;
          }
        }
      }

      label {
        font-size: 1.6rem;
        margin-left: 0.8rem;
      }
    }
  }
}
</style>
