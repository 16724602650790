<template>
  <router-view :db="db" />
</template>
<script>
import { Database } from "./database.js";
export default {
  created() {
    this.db = new Database();
  },
};
</script>