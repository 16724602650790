<template>
  <button
    :class="[
      'rounded-button',
      primary ? 'primary' : alternate ? 'alternate' : 'secondary',
      active ? 'active' : '',
      slim ? 'slim' : '',
      mediumwide ? 'medium-wide' : '',
      wide ? 'wide' : '',
      extrawide ? 'extrawide' : '',
      shadow ? 'shadow' : '',
      dark ? 'dark' : '',
      darker ? 'darker' : '',
      image ? 'image' : '',
      text && image ? 'text-and-image' : '',
      reverse ? 'reverse' : '',
      secondaryFont ? 'secondary-font' : '',
      bold ? 'bold' : '',
      smallerFont ? 'smaller-font' : '',
      fitWidth ? 'fit-width' : '',
      outline ? 'outline' : '',
      monospace ? 'monospace' : '',
    ]"
    :disabled="disabled"
    @click="onClick"
    :type="type"
  >
    <span class="text">{{ text }}</span>
    <img
      v-if="image"
      :src="image"
      :class="smallerImage ? 'smaller-image' : ''"
    />
  </button>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    active: Boolean,
    primary: Boolean,
    alternate: Boolean,
    onClick: Function,
    image: String,
    smallerImage: Boolean,
    smallerFont: Boolean,
    text: String,
    slim: Boolean,
    mediumwide: Boolean,
    wide: Boolean,
    extrawide: Boolean,
    shadow: Boolean,
    dark: Boolean,
    darker: Boolean,
    type: String,
    reverse: Boolean,
    secondaryFont: Boolean,
    bold: Boolean,
    fitWidth: Boolean,
    outline: Boolean,
    monospace: Boolean,
  },
};
</script>

<style lang="scss">
.rounded-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: map-get($component-sizes, button-height);
  padding: 0 1.4rem;
  border-radius: 2.4rem;
  font-size: 1.4rem;
  white-space: nowrap;
  border: none;
  outline: none;

  &[disabled] {
    background-color: map-get($component-colours, button-colour-disabled);
    color: map-get($component-colours, button-font-colour-disabled);
  }

  &[hover] {
    background-color: map-get($component-colours, button-colour-disabled);
    color: map-get($component-colours, button-font-colour-disabled);
  }

  &.image {
    padding: 0 1rem;
  }

  &.outline {
    border-radius: 44rem;
  }

  &.monospace {
    text-transform: uppercase;
    letter-spacing: 0.125rem;
  }

  &.fit-width {
    width: 100%;
  }

  &.smaller-font {
    font-size: 1.4rem;
  }

  &.dark {
    background: map-get($component-colours, button-colour-dark-50);
    color: map-get($component-colours, button-font-colour-primary);
  }

  &.darker {
    background: map-get($component-colours, button-colour-dark-2);
    color: map-get($component-colours, button-font-colour-primary);

    &.outline {
      border: 0.2rem solid map-get($component-colours, button-colour-dark-2);
      background: transparent;
      color: map-get($component-colours, button-font-colour-dark);

      &:hover,
      &:active &:not([disabled]) {
        background: map-get($component-colours, button-colour-dark-2);
        color: map-get($component-colours, button-font-colour-primary);
      }
    }
  }

  &.reverse {
    flex-direction: row-reverse;

    img {
      margin-left: 0;
      margin-right: 0.2rem;
    }
  }

  &.secondary-font {
    font-family: $font-secondary;
    letter-spacing: 0.1rem;
  }

  &.bold {
    font-weight: bold;
  }

  img {
    margin-left: 1rem;

    &.smaller-image {
      height: 70%;
    }
  }
}

.rounded-button.slim {
  height: map-get($component-sizes, button-height-slim);
}

.rounded-button.medium-wide {
  min-width: map-get($component-sizes, button-min-width-medium-wide);
}

.rounded-button.wide {
  min-width: map-get($component-sizes, button-min-width-wide);
}

.rounded-button.extrawide {
  min-width: map-get($component-sizes, button-min-width-extrawide);
}

.rounded-button.shadow {
  box-shadow: $small-crisp-box-shadow;
}

.rounded-button.text-and-image {
  .text {
    // Balances the whitespace to make content look more centered
    margin-left: 0.4rem;
  }
}
</style>
