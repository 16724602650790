<template>
  <div class="message-list">
    <Message
      v-for="message in messageList"
      :key="message.timestamp"
      :author="message.author"
      :text="message.text"
      :timestamp="message.timestamp"
      :isMe="message.isMe"
      class="message"
      :class="message.isMe ? 'me' : ''"
    />
  </div>
</template>

<script>
import Message from "./Message";

export default {
  props: {
    messageList: Array,
  },

  components: {
    Message,
  },
};
</script>

<style lang="scss">
.message-list {
  // background: map-get($component-colours, side-panel-background-dark-mode);
  display: flex;
  flex-direction: column;
  padding: 1.6rem;

  > *:not(:first-child) {
    margin-top: 2rem;
  }

  .message {
    align-self: flex-start;
    max-width: 85%;

    &.me {
      align-self: flex-end;
    }
  }
}
</style>
