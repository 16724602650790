<template>
  <label class="wrapper flex items-center">
    <input
      class="checkbox"
      type="radio"
      :checked="isChecked"
      :value="value"
      @change="$emit('change', $event.target.value)"
    />
    <span class="checkmark" :style="{ backgroundColor: value }"></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    label: { type: String, default: "", required: true },
    modelValue: { default: "" },
    value: { type: String, default: undefined },
  },
  computed: {
    isChecked() {
      return this.modelValue == this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
/* Customize the label (the wrapper) */
.wrapper {
  display: inline-flex;
  position: relative;
  padding-left: 2.5rem;
  margin-bottom: 2.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default radio button */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  border: 1px solid #ccc;
}
/* On mouse-over, add a border */
.wrapper:hover input ~ .checkmark {
  border: 2px solid rgba(0, 0, 0, 0.25);
}
/* When the radio button is checked, add a black border */
.wrapper input:checked ~ .checkmark {
  border: 1.5px solid rgb(81, 81, 81);
}
</style>
