<template>
  <MessageScreen v-if="loading || errorLoading || !minLoadingTimeElapsed">
    <div class="loading-screen" v-if="loading || !minLoadingTimeElapsed">
      <span>Loading...</span>
      <ProgressSpinner class="spinner bottom" />
    </div>
    <div class="loading-screen" v-else>
      <span>{{ errorLoadingMessage }}</span>
      <RoundedButton text="Go back" shadow @click="goBack" class="bottom" />
    </div>
  </MessageScreen>

  <div v-else class="editor">
    <div class="top-section">
      <header
        class="main-header-mobile main-header"
        :class="mobileHeaderCollapsed ? 'collapsed' : ''"
      >
        <RoundedSquareButton
          :image="ExpandIcon"
          imageAlt="Expand"
          :onClick="collapseHeader"
          v-tooltip.top="'Expand content'"
          smallImg
          :transparentLight="!this.$store.state.lightModeActive"
          :transparentDark="this.$store.state.lightModeActive"
          noShadow
        />
        <img
          class="logo mobile"
          :src="this.$store.state.lightModeActive ? MPLogoDark : MPLogoLight"
        />
        <RoundedSquareButton
          :image="!this.$store.state.lightModeActive ? GridIcon : GridDarkIcon"
          imageAlt="App grid"
          :onClick="toggleAppGrid"
          v-tooltip.top="'App grid'"
          smallImg
          :transparentLight="!this.$store.state.lightModeActive"
          :transparentDark="this.$store.state.lightModeActive"
          noShadow
          :active="appGridOpen"
        />
        <button
          class="uncollapse-header-button"
          :class="mobileHeaderCollapsed ? 'show' : ''"
          @click="expandHeader"
          v-tooltip.right="'Expand header'"
        >
          <img :src="CloseIcon" />
        </button>
      </header>
      <header class="main-header-desktop main-header">
        <img
          class="logo desktop"
          :src="
            this.$store.state.lightModeActive
              ? MPHabitatLogoDark
              : MPHabitatLogoLight
          "
        />
        <RoundedSquareButton
          :image="!this.$store.state.lightModeActive ? GridIcon : GridDarkIcon"
          imageAlt="App grid"
          v-tooltip.bottom="'App grid'"
          smallImg
          class="app-grid-button"
          :onClick="toggleAppGrid"
          :transparentLight="!this.$store.state.lightModeActive"
          :transparentDark="this.$store.state.lightModeActive"
          noShadow
          large
          :active="appGridOpen"
        />
      </header>
      <div class="editor-body">
        <SidePanel
          :class="sidePanelOpen ? 'show' : ''"
          class="side-panel"
          :isOpen="sidePanelOpen"
          :SidePanels="SidePanels"
          :activePanel="activePanel"
          :users="users"
          :incrementNumUnreadMessages="incrementNumUnreadMessages"
          :clearNumUnreadMessages="clearNumUnreadMessages"
          :currentUser="currentUser"
          :updateCurrentUser="updateCurrentUser"
          :unreadMessages="unreadMessages"
          :closeSidePanel="closeSidePanel"
          :closeWelcomePanel="closeWelcomePanel"
        />
        <div class="editor-content-wrapper" ref="editorContentWrapper">
          <div
            class="gradient top-gradient"
            :class="isScrolledToTop ? 'hide' : ''"
          />
          <editor-content
            :editor="editor"
            ref="editor"
            class="editor-content"
            @scroll.passive="this.handleScroll"
            :style="{
              color: fontColour,
              fontWeight: fontBold ? 'bold' : 'normal',
              fontFamily: fontFamily,
              fontSize: fontSize,
            }"
          />
          <div
            class="resize-handle-wrapper"
            ref="resizer"
            @mousedown="initResize"
          >
            <div class="resize-handle" />
            <img
              :src="
                this.$store.state.lightModeActive
                  ? ResizeArrowsDark
                  : ResizeArrows
              "
              draggable="false"
              class="resize-arrows-image"
            />
            <img
              :src="ResizeHandle"
              draggable="false"
              class="resize-handle-image"
              :class="draggingResizeHandle ? 'dragging' : ''"
            />
          </div>
          <div class="gradient" :class="isScrolledToBottom ? 'hide' : ''" />
        </div>
      </div>
    </div>
    <div class="editor__footer">
      <div class="button-bar">
        <div
          class="switch-wrapper"
          v-tooltip.right="'Scroll to keep up with incoming text'"
        >
          <span class="switch-label">Autoscroll</span>
          <InputSwitch v-model="autoscroll" />
        </div>
        <div class="button-section">
          <RoundedSquareButton
            :image="ChatIcon"
            :accentImg="chatOpen"
            imageAlt="Chat"
            :onClick="toggleChatOpen"
            class="toggle-chat-button"
            :class="numTotalUnreadMessages > 0 ? '' : 'hide-badge'"
            v-badge="numTotalUnreadMessages"
            v-tooltip.right="'Student-captioner chat'"
          />
          <RoundedSquareButton
            :image="fontFormatIcon"
            imageAlt="Font format"
            :onClick="toggleOpenMenu"
            v-tooltip.top="'Font format menu'"
          />
          <RoundedSquareButton
            :image="SettingsIcon"
            imageAlt="Settings"
            :onClick="toggleSettingsMenu"
            v-tooltip.top="'Settings menu'"
          />
          <RoundedSquareButton
            :image="DownloadIcon"
            imageAlt="Download transcript"
            :onClick="downloadTranscript"
            v-tooltip.top="'Download transcript'"
          />
          <div :class="`editor__status editor__status--${status}`">
            <template v-if="status === 'connected'">
              {{ numCaptioners }} Captioner{{ numCaptioners === 1 ? "" : "s"
              }}{{ numOther > 0 ? ", " : " and " }}{{ numStudents }} Guest{{
                numStudents === 1 ? "" : "s"
              }}{{
                numOther > 0
                  ? ", and " + numOther + " other" + (numOther > 1 ? "s" : "")
                  : ""
              }}
              are viewing this session
            </template>
            <template v-else> offline </template>
          </div>
        </div>
      </div>
      <FontFormatMenu
        ref="ffm"
        v-bind:db="db"
        :userPreferences="this.userPreferences"
        :FontColours="FontColours"
        :changeFontColour="changeFontColour"
        :toggleFontBold="toggleFontBold"
        :fontBold="this.fontBold"
        :changeFontFamily="changeFontFamily"
        :changeFontSize="changeFontSize"
        :setClosed="setFFMClosed"
      />
      <AppGrid ref="appGrid" />
      <ModalBackground
        class="mobile-only"
        :showing="ffmOpen || chatOpen"
        @closed="onModalClosed"
      />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import ModalBackground from "../components/ModalBackground.vue";
import RoundedSquareButton from "./RoundedSquareButton";
import fontFormatIcon from "../assets/images/FontFormat.svg";
import SettingsIcon from "../assets/images/Settings.svg";
import leaveIcon from "../assets/images/Leave.svg";
import FontFormatMenu from "./FontFormatMenu";
import AppGrid from "./AppGrid";
import SidePanel from "../components/SidePanel/SidePanel";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import * as Y from "yjs";
import { HocuspocusProvider } from '@hocuspocus/provider';
import ChatIcon from "../assets/images/Chat.svg";
import ChatIconWhite from "../assets/images/Chat-white.svg";
import DownloadIcon from "../assets/images/Download.svg";
import MessageScreen from "../components/MessageScreen.vue";
import RoundedButton from "../components/RoundedButton.vue";
import ResizeHandle from "../assets/images/ResizeHandle.svg";
import ResizeArrows from "../assets/images/ResizeArrows.svg";
import ResizeArrowsDark from "../assets/images/ResizeArrowsDark.svg";
import HabitatLogo from "../assets/images/logos/Habitat-Logo.svg";
import MPHabitatLogoDark from "../assets/images/logos/MP-Habitat-Logo-Dark.svg";
import MPHabitatLogoLight from "../assets/images/logos/MP-Habitat-Logo-Light.svg";
import MPLogoDark from "../assets/images/logos/MP-Logo-Dark.svg";
import MPLogoLight from "../assets/images/logos/MP-Logo-Light.svg";
import ExpandIcon from "../assets/images/Expand.svg";
import GridIcon from "../assets/images/Grid.svg";
import GridDarkIcon from "../assets/images/Grid-Dark.svg";
import CloseIcon from "../assets/images/Close.svg";
import { convertRemToPixels } from "../assets/helpers";
import { dbConstants } from "../assets/constants";
import _ from "lodash";
import axios from "axios";
import { docx, Paragraph, TextRun, Document, Packer } from "docx"

const SidePanels = {
  WELCOME: 0,
  CHAT: 1,
  SETTINGS: 2,
  OTHER: 3,
};

const FontColours = {
  WHITE: "#F6F6F6",
  BLACK: "#474747",
  YELLOW: "#FFEB00",
  LIME: "#89FF00",
  BLUE: "#30C7FF",
  TURQUOISE: "#14ED91",
};

export default {
  props: {
    db: Object,
    users: Array,
    numTotalUnreadMessages: Number,
    incrementNumUnreadMessages: Function,
    clearNumUnreadMessages: Function,
    unreadMessages: Map,
    setUsers: Function,
    currentUser: Object,
    updateCurrentUser: Function,
    setEditor: Function,
    editor: Object,
  },

  components: {
    EditorContent,
    RoundedSquareButton,
    FontFormatMenu,
    SidePanel,
    MessageScreen,
    RoundedButton,
    ModalBackground,
    AppGrid,
  },

  data() {
    return {
      provider: null,
      status: "connecting",
      SidePanels,
      FontColours,

      fontFormatIcon,
      leaveIcon,
      ChatIcon,
      ChatIconWhite,
      DownloadIcon,
      SettingsIcon,
      ResizeHandle,
      ResizeArrows,
      ResizeArrowsDark,
      HabitatLogo,
      MPHabitatLogoDark,
      MPHabitatLogoLight,
      MPLogoDark,
      MPLogoLight,
      ExpandIcon,
      GridIcon,
      GridDarkIcon,
      CloseIcon,

      anchorElFont: false,
      fontColour: this.$store.state.lightModeActive
        ? FontColours.BLACK
        : FontColours.WHITE,
      fontBold: false,
      fontFamily: "Averta",
      fontSize: "2.8rem",
      numCaptioners: 0,
      numStudents: 0,
      numOther: 0,
      autoscroll: true,
      isScrolledToBottom: false,
      isScrolledToTop: true,
      ffmOpen: false,
      appGridOpen: false,
      chatOpen: false,
      settingsOpen: false,
      sidePanelOpen: true,
      activePanel: SidePanels.WELCOME,
      mobileHeaderCollapsed: false,
      welcomePanelClosed: false,

      loading: true,
      errorLoading: false,
      errorLoadingMessage: "",
      MIN_LOADING_TIME: 1000,
      minLoadingTimeElapsed: false,

      showFeedbackForm: false,

      draggingResizeHandle: false,

      userPreferences: null,
    };
  },

  computed: {
    editorUsers() {
      return this.editor ? this.editor.storage.collaborationCursor.users : [];
    },
  },

  watch: {
    "$store.state.lightModeActive": function () {
      // If switched to lightmode and font was white, change to it black
      if (this.$store.state.lightModeActive) {
        if (this.fontColour === this.FontColours.WHITE) {
          this.fontColour = this.FontColours.BLACK;
        }
      } else {
        if (this.fontColour === this.FontColours.BLACK) {
          this.fontColour = this.FontColours.WHITE;
        }
      }
    },

    editorUsers: function (users) {
      let numCaptioners = 0;
      let numStudents = 0;
      let numOther = 0;

      // Fix for duplicate user bug with collaboration extension
      const uniqueUsers = users.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.uid === value.uid)
      );

      this.setUsers(uniqueUsers);

      for (let i = 0; i < uniqueUsers.length; i++) {
        if (
          uniqueUsers[i].type === dbConstants.EDITOR_USER_TYPES.SERVICE_PROVIDER
        ) {
          numCaptioners++;
        } else if (
          uniqueUsers[i].type === dbConstants.EDITOR_USER_TYPES.STUDENT
        ) {
          numStudents++;
        } else {
          numOther++;
        }
      }
      this.numCaptioners = numCaptioners;
      this.numStudents = numStudents;
      this.numOther = numOther;
    },
  },

  methods: {
    handleScroll: _.debounce(function (e) {
      if (
        // there's 1em of margin below the last line that is not scrolled into view with ProseMirror's scrollIntoView()
        e.target.clientHeight + e.target.scrollTop <
        e.target.scrollHeight
      ) {
        this.isScrolledToBottom = false;
      } else {
        this.isScrolledToBottom = true;
      }

      if (e.target.scrollTop <= 0) {
        this.isScrolledToTop = true;
      } else {
        this.isScrolledToTop = false;
      }
    }, 100),

    closeWindow() {
      this.$router.push("/");
    },

    openFeedbackForm() {
      this.showFeedbackForm = true;
    },

    closeFeedbackForm() {
      this.showFeedbackForm = false;
    },

    toggleOpenMenu(event) {
      this.$refs.ffm.toggle(event);
      if (!this.ffmOpen) {
        this.ffmOpen = true;
      } else {
        this.setFFMClosed();
      }
    },

    setFFMClosed() {
      this.ffmOpen = false;
    },

    toggleAppGrid(event) {
      this.$refs.appGrid.toggle(event);
      if (!this.appGridOpen) {
        this.appGridOpen = true;
      } else {
        this.setAppGridClosed();
      }
    },

    setAppGridClosed() {
      this.appGridOpen = false;
    },

    toggleChatOpen() {
      this.toggleSidePanel(this.SidePanels.CHAT);
    },

    closeChat() {
      this.chatOpen = false;
      this.setShowChatMainMenu(true);
    },

    toggleSettingsMenu() {
      this.toggleSidePanel(this.SidePanels.SETTINGS);
    },

    closeSettings() {
      this.settingsOpen = false;
    },

    toggleSidePanel(panel) {
      if (!this.sidePanelOpen) {
        this.sidePanelOpen = true;
        this.activePanel = panel;
      } else {
        if (panel === this.activePanel) {
          // The panel is already open so close it
          this.closeSidePanel();
        } else {
          // Switch panels
          this.activePanel = panel;
        }
      }
    },

    closeSidePanel() {
      if (this.welcomePanelClosed) {
        this.sidePanelOpen = false;
      } else {
        this.activePanel = SidePanels.WELCOME;
      }
    },

    closeWelcomePanel() {
      this.sidePanelOpen = false;
      this.welcomePanelClosed = true;
    },

    collapseHeader() {
      this.mobileHeaderCollapsed = true;
    },

    expandHeader() {
      this.mobileHeaderCollapsed = false;
    },

    changeFontColour(hex) {
      this.fontColour = hex;
      this.db.updatePreference(dbConstants.PREFERENCE_FONT_COLOUR, hex);
    },

    toggleFontBold() {
      this.fontBold = !this.fontBold;
      this.db.updatePreference(dbConstants.PREFERENCE_FONT_BOLD, this.fontBold);
    },

    changeFontFamily(family) {
      this.fontFamily = family;
      this.db.updatePreference(dbConstants.PREFERENCE_FONT_FAMILY, family);
    },

    changeFontSize(size) {
      this.fontSize = size / 10 + "rem";
      this.db.updatePreference(dbConstants.PREFERENCE_FONT_SIZE, this.fontSize);
    },

    scrollToBottom() {
      if (this.$refs.editor) {
        this.$refs.editor.$el.scrollTop = this.$refs.editor.$el.scrollHeight;
      }
    },

    onModalClosed() {
      this.ffmOpen = false;
      this.chatOpen = false;
    },

    downloadTranscript() {
      const docJSON = this.editor.getJSON();
      const currDate = new Date();
      const fileDateSuffix =
        currDate.getDate() +
        "-" +
        currDate.getMonth() +
        "-" +
        currDate.getFullYear() +
        "_" +
        currDate.getHours() +
        "_" +
        currDate.getMinutes();
      
      this.generateTranscript(
        docJSON,
        "Arial",
        this.$route.params.document +
        "-notes" +
        fileDateSuffix +
        ".docx",
      ).catch(error => console.error(error))
    },

    async generateTranscript(
      transcript,
      fontFile,
      filePath,
    ) {
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = String(today.getFullYear());

      today = mm + "-" + dd + "-" + yyyy;

      let doc;
      const fileName = filePath;
      let paragraphArray = [];
      if (fontFile === "Averta") {
        fontFile = "Arial";
      } else if (fontFile === "Open Dyslexic") {
        fontFile = "Courier New";
      } else if (fontFile === "Comic Sans") {
        fontFile = fontFile + " MS";
      }
      transcript.content.forEach((element) => {
        if (element.type == "paragraph") {
          const para = new Paragraph({});
          if ("content" in element) {
            for (let item of element.content) {
              if (item.type === "text") {
                let bold = false;
                let italic = false;
                let underline = false;

                if (item.marks) {
                  for (let mark of item.marks) {
                    if (mark.type === "bold") {
                      bold = true;
                    } else if (mark.type === "italic") {
                      italic = true;
                    } else if (mark.type === "underline") {
                      underline = {};
                    }
                  }
                }
                let text = new TextRun({
                  text: item.text,
                  font: {
                    // name: "Comic Sans MS",
                    name: fontFile ? fontFile : "Helvetica",
                  },
                  size: 22,
                  bold: bold,
                  italics: italic,
                  underline: underline,
                });

                para.addChildElement(text);
              } else if (item.type === "hardBreak") {
                para.addChildElement(
                  new TextRun({
                    text: "",
                    break: 1,
                  })
                );
              }
            }
          }

          // Add spacing after each paragraph
          para.addChildElement(
            new TextRun({
              text: "",
              break: 1,
            })
          );
          paragraphArray.push(para);
        } else if (element.type == "heading") {
          if (Object.prototype.hasOwnProperty.call(element, "content")) {
            const heading = new Paragraph({
              children: [
                new TextRun({
                  text: element.content[0].text,
                  heading: docx.HeadingLevel.HEADING_1,
                  font: {
                    // name: "Comic Sans MS",
                    name: fontFile ? fontFile : "Helvetica",
                  },
                  size: 32,
                  bold: true,
                }),
              ],
            });
            paragraphArray.push(heading);
          }
        }
      });
      doc = new Document({
        sections: [
          {
            properties: {},
            children: paragraphArray,
          },
        ],
      });

      Packer.toBlob(doc).then((blob) => {
        const url = window.URL.createObjectURL(blob);

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(url);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        let link = document.createElement("a");
        link.href = url;
        link.download = fileName + ".docx";
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(link);
        }, 100);
         });
    },

    deleteAfterDownload(doc) {
      axios
        .get("https://mp-server.habitatlearn.com/deleteAfterDownload/" + doc)
        .catch((error) => {
          console.log(error);
        });
    },

    goBack() {
      this.$router.push("/");
    },

    // Methods for resizing the editor
    initResize() {
      window.addEventListener("mousemove", this.Resize, false);
      window.addEventListener("mouseup", this.stopResize, false);
      this.draggingResizeHandle = true;
    },
    //resize the element
    Resize(e) {
      // cursor posiiton minus top of editor plus half the height of the resize handle
      this.$refs.editorContentWrapper.style.height =
        e.clientY -
        this.$refs.editorContentWrapper.offsetTop +
        convertRemToPixels(4 / 2) +
        "px";
    },
    //on mouseup remove windows functions mousemove & mouseup
    stopResize() {
      window.removeEventListener("mousemove", this.Resize, false);
      window.removeEventListener("mouseup", this.stopResize, false);
      this.draggingResizeHandle = false;
    },

    async initPreferences() {
      try {
        const size = await this.db.getPreference(
          dbConstants.PREFERENCE_FONT_SIZE
        );
        this.fontSize = size.value;
      } catch (err) {
        console.log(err);
      }
      try {
        const colour = await this.db.getPreference(
          dbConstants.PREFERENCE_FONT_COLOUR
        );
        this.fontColour = colour.value;
      } catch (err) {
        console.log(err);
      }
      try {
        const bold = await this.db.getPreference(
          dbConstants.PREFERENCE_FONT_BOLD
        );
        this.fontBold = bold.value;
      } catch (err) {
        console.log(err);
      }
      try {
        const family = await this.db.getPreference(
          dbConstants.PREFERENCE_FONT_FAMILY
        );
        this.fontFamily = family.value;
      } catch (err) {
        console.log(err);
      }
      this.userPreferences = {
        fontSize: this.fontSize,
        fontColour: this.fontColour,
        fontBold: this.fontBold,
        fontFamily: this.fontFamily,
      };
    },
  },

  created() {
    this.initPreferences();
  },

  mounted() {
    const SERVICE_TYPE_CAPTIONING = "Live Captioning";

    // So the loading screen stays for at least a second or two instead of flashing
    setTimeout(() => {
      this.minLoadingTimeElapsed = true;
    }, this.MIN_LOADING_TIME);

    // Loading booking details
    var myHeaders = new Headers();
    myHeaders.append("content-type", "application/json");
    myHeaders.append(
      "x-hasura-admin-secret",
      process.env.VUE_APP_HASURA_ADMIN_SECRET
    );
    var graphql = JSON.stringify({
      query: `query GetBookingInfo($id: uuid!) {
                    calendar_tasks_by_pk(id: $id) {
                      start_time,
                      end_time,
                      tz,
                      name,
                      service_type,
                      labels
                    }
                  }
                `,
      variables: {
        id: this.$route.params.document,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
    };
    fetch(process.env.VUE_APP_HASURA_URL, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        const noBooking = "Booking not found";
        const table = "calendar_tasks_by_pk";

        if (res.errors) {
          console.log("errors", res.errors);
          this.loading = false;
          this.errorLoading = true;
          if (
            res.errors[0].message.includes("invalid input syntax for type uuid")
          ) {
            this.errorLoadingMessage = noBooking;
          } else {
            this.errorLoadingMessage =
              "Received error: " + res.errors[0].message;
          }
        } else if (
          res.data[table] &&
          res.data[table].service_type === SERVICE_TYPE_CAPTIONING
        ) {
          // good booking
          const ydoc = new Y.Doc();
          this.provider = new HocuspocusProvider({
            url: "wss://mp-collab-server.habitatlearn.com/collaboration",
            document: ydoc,
            name: this.$route.params.document,
            token: "secret-token",
          });
          this.provider.on("status", (event) => {
            this.status = event.status;
          });

          this.$store.commit("setSessionDetails", res.data[table]);

          window.ydoc = ydoc;

          let ref = this;

          this.setEditor(
            new Editor({
              extensions: [
                StarterKit.configure({
                  history: false,
                }),
                Highlight,
                CollaborationCursor.configure({
                  provider: this.provider,
                  user: this.currentUser,
                }),
                Collaboration.configure({
                  document: ydoc,
                }),
              ],
              editable: false,
              onUpdate() {
                if (ref.autoscroll) {
                  ref.scrollToBottom();
                }
              },
            })
          );
          this.loading = false;
        } else {
          this.loading = false;
          this.errorLoading = true;
          this.errorLoadingMessage = noBooking;
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.loading = false;
        this.errorLoadingMessage = "Error retrieving booking information";
        this.errorLoading = true;
      });
  },
};
</script>

<style lang="scss">
@import "../styles/_mixins.scss";

$editor-max-width: 720;
$outside-padding: 1.6rem;

.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;

  .spinner {
    stroke: map-get($component-colours, progress-spinner-1);
    height: 10rem;
    width: 10rem;
  }

  .bottom {
    margin-top: 2rem;
  }
}

.editor {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .top-section {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    flex: 1;

    .main-header {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.1rem solid
        map-get($component-colours, border-light-solid);
    }

    .main-header-mobile {
      padding: 0 2rem;
      display: flex;
      height: 9.5rem;
      overflow: hidden;
      transition: height 0.5s;

      @include cwh(550, 550) {
        display: none;
      }

      &.collapsed {
        height: 0;
      }

      .logo {
        height: 4.8rem;
      }

      .uncollapse-header-button {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        top: $outside-padding;
        left: $outside-padding;
        z-index: 10;
        padding: 0.5rem;
        line-height: 0;
        border-radius: 50%;
        box-shadow: $small-crisp-box-shadow;

        &.show {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.4s 0.1s;
        }
      }
    }

    .main-header-desktop {
      padding: 2.4rem 3.2rem;
      display: none;

      @include cwh(550, 550) {
        display: flex;
      }

      .logo {
        height: 9rem;
      }
    }

    .editor-body {
      overflow-y: hidden;
      display: flex;
      flex: 1;

      @include cwh(750, 550) {
        position: relative;
      }

      .side-panel {
        width: 0;
        min-width: 0;
        height: 100vh;
        top: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        z-index: 1000;

        &.show {
          width: 100vw;
        }

        @include cwh(750, 550) {
          position: initial;
          height: calc(100% - 2 *#{$outside-padding});
          overflow: hidden;
          transition: 0.5s;
          margin-left: $outside-padding;
          margin-top: $outside-padding;
          box-shadow: $side-panel-shadow;
          border-radius: $side-panel-border-radius;

          &.show {
            width: $side-panel-width-in-rem + rem;
            min-width: $side-panel-width-in-rem + rem;
          }
        }

        @include rwd(1460) {
          position: absolute;
        }
      }

      .editor-content-wrapper {
        height: 67%;
        width: 100%;
        max-width: $editor-max-width + px;
        max-height: 100%;
        min-height: 10rem;
        overflow-y: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: flex-start;
        margin: 0 auto;

        .editor-content {
          flex: 1;
          overflow: auto;
          width: calc(100% - 1rem); // subtract margin
          margin-right: 1rem;
          scroll-behavior: smooth;

          .ProseMirror {
            height: calc(100% - 1.2em);
            outline: none;
            padding: 0 $outside-padding;

            span {
              // Hide others' highlights
              background-color: transparent !important;
            }
          }
        }

        .resize-handle-wrapper {
          cursor: ns-resize;
          position: relative;
          width: calc(
            100% - #{$outside-padding} * 2
          ); // subtract the margins on either side of the editor
          height: $outside-padding;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 1rem 0;
          z-index: 2;

          &:hover {
            .resize-arrows-image {
              opacity: 0;
            }

            .resize-handle-image {
              opacity: 1;
            }

            .resize-handle {
              background-color: map-get($component-colours, border-light-solid);

              .light-mode-active & {
                background-color: map-get(
                  $component-colours,
                  border-dark-solid
                );
              }
            }
          }

          .resize-arrows-image {
            height: 1.5rem;
            opacity: 1;
            transition: opacity 0.15s;
          }

          .resize-handle-image {
            opacity: 0;
            transition: opacity 0.15s;
            position: absolute;
            height: 2rem;
            filter: drop-shadow(0px 0.25rem 0.25rem rgba(0, 0, 0, 0.2));

            &.dragging {
              filter: drop-shadow(0px 0.25rem 0.25rem rgba(0, 0, 0, 0.2))
                brightness(70%);
            }
          }

          .resize-handle {
            width: 100%;
            height: 0.1rem;
            background-color: map-get($component-colours, border-light);
            position: absolute;
            transition: background-color 0.15s;

            .light-mode-active & {
              background-color: map-get($component-colours, border-dark);
            }
          }
        }

        // The gradient above the bottom bar for text editors
        .gradient {
          background: linear-gradient(
            180deg,
            rgba(135, 117, 148, 0) 29.17%,
            map-get($component-colours, background-primary) 99.58%
          );
          width: calc(100% - #{$outside-padding}); // don't cover the scrollbar
          height: 4rem;
          position: absolute;
          bottom: calc(
            2 * 1rem + #{$outside-padding}
          ); // total height of resize handle including margins
          left: 0;
          z-index: 1;
          border-radius: 1rem 1rem 0 0;
          transition: opacity 0.25s ease-in-out;
          pointer-events: none;

          .light-mode-active & {
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0%,
              #ffffff 100%
            );
          }

          &.top-gradient {
            top: 0;
            transform: rotate(180deg);
          }

          &.hide {
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
          }
        }
      }
    }
  }

  .editor__status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    color: map-get($component-colours, font-grey-2);
    font-family: $font-secondary;
    font-size: 1.2rem;
    font-weight: 400;
    display: none;

    .light-mode-active & {
      color: map-get($component-colours, font-grey-6);
    }

    @include rwd(620) {
      display: flex;
    }

    .give-feedback-button-mobile {
      @include rwd(450) {
        display: none;
      }
    }
  }

  .button-bar {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .switch-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .switch-label {
        font-size: 1.4rem;
        margin-bottom: 0.8rem;
        font-weight: 600;

        .light-mode-active & {
          color: map-get($component-colours, font-grey-6);
        }
      }
    }

    .button-section {
      display: flex;
      flex-direction: row;
    }

    .button-section > *:not(:first-child) {
      margin-left: 1.6rem;
    }

    .give-feedback-button {
      margin-right: 1.4rem;
      margin-bottom: 0.4rem;
      align-self: flex-end;
      display: none;

      @include rwd(450) {
        display: block;
      }
    }
  }

  &__footer {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    white-space: nowrap;
    font-size: 1.5rem;
    color: white;
    white-space: nowrap;
    padding: 0 $outside-padding $outside-padding;
  }

  a {
    color: map-get($component-colours, link-font-colour);
    cursor: pointer;
  }
}

// Remote user caret
.collaboration-cursor__caret {
  display: none;
}

// Username above caret
.collaboration-cursor__label {
  display: none;
}

mark {
  background-color: map-get($component-colours, background-primary);
  color: map-get($component-colours, font-colour-edited);

  .light-mode-active & {
    background-color: map-get(
      $component-colours,
      background-primary-light-mode
    );
    color: map-get($component-colours, font-colour-edited-light-mode);
  }
}

::selection {
  background-color: map-get(
    $component-colours,
    font-background-colour-highlighted
  );

  color: map-get($component-colours, font-colour-highlighted);
}

::-moz-selection {
  color: map-get($component-colours, font-colour-highlighted);
  background-color: map-get(
    $component-colours,
    font-background-colour-highlighted
  );
}
</style>
