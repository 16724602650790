<template>
  <div
    class="chat"
    :class="this.$store.state.lightModeActive ? 'light-mode-active' : ''"
  >
    <div class="content-wrapper">
      <SidePanelHeader
        :title="partnerUser.name"
        :clickClose="onClose"
        :clickBack="() => setShowChatMainMenu(true)"
      />
      <MessageList
        :messageList="messageList"
        class="message-list"
        ref="messageList"
        @scroll.passive="this.handleScroll"
      />
      <div class="message-input-section">
        <div
          class="disconnected-overlay"
          v-if="
            (websocket ? websocket.readyState !== websocket.OPEN : true) ||
            pusherState !== 'connected'
          "
        >
          Chat disconnected
        </div>
        <textarea
          class="message-input"
          placeholder="Type your message here"
          :value="currentMessage"
          @input="input($event.target.value)"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="trySendMessage()"
        />
        <IconButton
          :icon="
            this.$store.state.lightModeActive
              ? DoneArrowIcon
              : DoneArrowWhiteIcon
          "
          alt="Send message"
          :onclick="trySendMessage"
          xsmall
          dark
          addPadding
        />
      </div>
    </div>
  </div>
</template>

<script>
import MessageList from "./MessageList";
import SidePanelHeader from "../SidePanelHeader";
import IconButton from "../../IconButton";
import DoneArrowIcon from "../../../assets/images/DoneArrow.svg";
import DoneArrowWhiteIcon from "../../../assets/images/DoneArrow-White.svg";

import _ from "lodash";

export default {
  components: {
    MessageList,
    IconButton,
    SidePanelHeader,
  },

  props: {
    isOpen: Boolean,
    username: String,
    partnerUser: Object,
    toggleChatOpen: Function,
    closeChat: Function,
    incrementNumUnreadMessages: Function,
    clearNumUnreadMessages: Function,
    websocket: Object,
    pusherState: String,
    sessionName: String,
    pusher: Object,
    socketId: String,
    setShowChatMainMenu: Function,
  },

  data() {
    return {
      DoneArrowIcon,
      DoneArrowWhiteIcon,
      currentMessage: "",
      isScrolledToBottom: true,
      messageLists: JSON.parse(localStorage.getItem("messageLists")) || {},
      channel: null,
    };
  },

  computed: {
    messageList() {
      if (this.messageLists[this.sessionName]) {
        return this.messageLists[this.sessionName][this.partnerUser.uid] || [];
      }
      return [];
    },
  },

  watch: {
    messageList: {
      handler(updated) {
        const list = this.$refs.messageList;
        if (
          // Scrolled to the bottom
          list.$el.scrollHeight -
            (list.$el.scrollTop + list.$el.offsetHeight) <=
          0.5
        ) {
          this.$nextTick(() => {
            // Scroll to the bottom once the message is rendered
            this.scrollToBottom();
          });
        }
        this.messageLists[this.sessionName][this.partnerUser.uid] = updated;
        localStorage.setItem("messageLists", JSON.stringify(this.messageLists));
      },
      deep: true,
    },
  },

  mounted() {
    this.scrollToBottom();
    this.channel = this.pusher.subscribe(this.sessionName);
    this.channel.bind("new-chat-message", (message) => {
      this.addMessageToList(message);
      if (!this.isOpen || !this.isScrolledToBottom) {
        this.incrementNumUnreadMessages(this.partnerUser.uid);
      }
    });
  },

  unmounted() {
    this.pusher.unsubscribe(this.sessionName);
  },

  methods: {
    toggle(event) {
      this.$refs.chat.toggle(event);
    },

    input(val) {
      this.currentMessage = val;
    },

    trySendMessage() {
      if (this.sendMessage(this.currentMessage)) {
        this.currentMessage = "";
      }
    },

    sendMessage(currentMessage) {
      if (currentMessage.trim().length === 0) {
        return false;
      }

      if (this.websocket.readyState !== this.websocket.OPEN) {
        return false;
      }

      let now = new Date();

      const message = {
        author: this.username,
        text: currentMessage,
        timestamp: now.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      };

      const messageData = {
        message: message,
        sessionName: this.sessionName,
        socketId: this.socketId,
      };

      this.websocket.send(JSON.stringify(messageData));

      message.isMe = true;
      message.author = "Me";
      this.addMessageToList(message);

      this.$nextTick(() => {
        // Scroll to the bottom once the message is rendered
        this.scrollToBottom();
      });

      return true;
    },

    addMessageToList(message) {
      if (this.messageLists[this.sessionName]) {
        if (this.messageLists[this.sessionName][this.partnerUser.uid]) {
          this.messageLists[this.sessionName][this.partnerUser.uid].push(
            message
          );
        } else {
          this.messageLists[this.sessionName][this.partnerUser.uid] = [message];
        }
      } else {
        this.messageLists[this.sessionName] = {
          [this.partnerUser.uid]: [message],
        };
      }
    },

    scrollToBottom() {
      this.$refs.messageList.$el.scrollTop = this.$refs.messageList.$el.scrollHeight;
    },

    handleScroll: _.debounce((e) => {
      if (e.target.clientHeight + e.target.scrollTop < e.target.scrollHeight) {
        this.isScrolledToBottom = false;
      } else {
        this.isScrolledToBottom = true;
        this.clearNumUnreadMessages(this.partnerUser.uid);
      }
    }, 100),

    onClose() {
      this.closeChat();
      setTimeout(() => this.setShowChatMainMenu(true), 400);
    },
  },
};
</script>

<style lang="scss">
.chat {
  background: map-get($component-colours, side-panel-background-dark-mode);
  color: map-get($component-colours, side-panel-font-primary-dark-mode);
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: width 0.2s, min-width 0.2s;

  &.light-mode-active {
    background-color: map-get(
      $component-colours,
      side-panel-background-light-mode
    );
    color: map-get($component-colours, side-panel-font-primary-light-mode);
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .message-list {
      flex: 1;
      overflow: auto;
      scroll-behavior: smooth;
    }

    .message-input-section {
      border-top: 0.1rem solid map-get($component-colours, border-light);
      display: flex;
      flex-direction: row;
      align-items: center;
      background: map-get($component-colours, side-panel-background-dark-mode);
      position: relative;

      textarea {
        background: transparent;
        color: map-get($component-colours, side-panel-font-primary-dark-mode);

        &::placeholder {
          color: map-get($colours, translucent-white-60);
        }
      }

      .light-mode-active & {
        background: map-get(
          $component-colours,
          side-panel-background-light-mode
        );
        color: map-get($component-colours, side-panel-font-primary-light-mode);
        border-top: 0.1rem solid map-get($component-colours, border-light-solid);
      }

      .disconnected-overlay {
        position: absolute;
        top: 0;
        left: 0;
        background: inherit;
        font-family: inherit;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }

      .message-input {
        font-size: 1.6rem;
        margin: 1.4rem;
        margin-right: 0.4rem;
        padding-right: 0.7rem;
        resize: none;
        outline: none;
        border: none;
        flex: 1;
        font-family: $font-secondary;

        &::placeholder {
          color: map-get($component-colours, font-colour-alternate-secondary);
        }
      }
    }
  }
}
</style>
