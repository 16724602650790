<template>
  <div
    class="chat-list slid-out-left"
    :class="
      (isOpen ? 'slide-in' : 'slide-out',
      this.$store.state.lightModeActive ? 'light-mode-active' : '')
    "
  >
    <div class="chat-main side-panel-tab" v-show="showChatMainMenu">
      <SidePanelHeader title="Conversations" :clickClose="closeSidePanel" />
      <div class="side-panel-body">
        <div class="edit-display-name">
          <label for="username">Edit your display name</label>
          <InputText
            id="displayName"
            type="username"
            v-model="displayName"
            @change="displayNameChanged"
          >
            <img :src="Wave" alt=""
          /></InputText>
        </div>
        <div class="conversations">
          <p v-if="captionerUsers.length === 0" class="no-chat-partners">
            Available chat partners will appear here.
          </p>
          <button
            v-else
            v-for="(captionerUser, index) in captionerUsers"
            :key="captionerUser.uid"
            class="conversation"
            @click="changeTabIndex(captionerUser, index)"
          >
            <div class="left-section">
              <Avatar
                :label="captionerUser.name[0].toUpperCase()"
                size="xlarge"
                shape="circle"
              />
              <span class="user-name">{{ captionerUser.name }}</span>
              <span class="user-type">(captioner)</span>
            </div>
            <Badge
              v-if="this.unreadMessages.get(captionerUser.uid) > 0"
              :value="this.unreadMessages.get(captionerUser.uid)"
            ></Badge>
          </button>
        </div>
      </div>
    </div>
    <TabView v-show="!showChatMainMenu" v-model:activeIndex="activeTabIndex">
      <TabPanel
        v-for="captionerUser in captionerUsers"
        :key="captionerUser.uid"
        :header="captionerUser.name"
      >
        <Chat
          class="chat"
          :isOpen="
            !showChatMainMenu && activeChatPartnerUid === captionerUser.uid
          "
          :toggleChatOpen="toggleChatOpen"
          :closeChat="closeSidePanel"
          :username="displayName"
          :partnerUser="captionerUser"
          :sessionName="
            this.$route.params.document +
              captionerUser.uid +
              this.currentUser.uid
          "
          :incrementNumUnreadMessages="incrementNumUnreadMessages"
          :clearNumUnreadMessages="clearNumUnreadMessages"
          :changeTabIndex="changeTabIndex"
          :pusher="pusher"
          :socketId="socketId"
          :websocket="websocket"
          :pusherState="pusherState"
          :setShowChatMainMenu="callSetShowChatMainMenu"
        />
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import Chat from "./Chat";
import SidePanelHeader from "../SidePanelHeader";
import { LINKS, dbConstants } from "../../../assets/constants";
import Wave from "../../../assets/images/Wave.svg";
import Pusher from "pusher-js";

export default {
  props: {
    isOpen: Boolean,
    toggleChatOpen: Function,
    closeChat: Function,
    incrementNumUnreadMessages: Function,
    clearNumUnreadMessages: Function,
    users: Array,
    currentUser: Object,
    updateCurrentUser: Function,
    unreadMessages: Map,
    closeSidePanel: Function,
    showChatMainMenu: Boolean,
    setShowChatMainMenu: Function,
  },

  components: {
    Chat,
    SidePanelHeader,
  },

  data() {
    return {
      pusher: new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      }),
      socketId: null,
      websocket: null,
      pusherState: null,
      dbConstants: dbConstants,
      Wave,
      displayName: this.currentUser.name,
      activeTabIndex: 0,
      activeChatPartnerUid: null,
    };
  },

  mounted() {
    this.websocket = new WebSocket(LINKS.CHAT);
    this.websocket.onclose = (e) => {
      console.log(
        "Socket is closed. Reconnect will be attempted in 10 seconds.",
        e.reason
      );
      setInterval(() => {
        if (
          !this.websocket ||
          this.websocket.readyState !== this.websocket.OPEN
        ) {
          this.websocket = new WebSocket(LINKS.CHAT);
        }
      }, 5000);
    };

    // Enable pusher logging - don't include this in production
    if (process.env.NODE_ENV === "development") {
      Pusher.logToConsole = true;
    }

    this.pusher.connection.bind("connected", () => {
      this.socketId = this.pusher.connection.socket_id;
    });

    this.pusher.connection.bind("state_change", (states) => {
      this.pusherState = states.current;
    });

    this.pusher.connection.bind("error", (err) => {
      if (err.error)
        if (err.error.data.code === 4004) {
          console.log(">>> detected limit error");
        }
    });
  },

  computed: {
    captionerUsers() {
      return this.users.filter(
        (user) => user.type === "Worker"
      );
    },
  },

  watch: {
    users: function(newVal) {
      if (!newVal.find((user) => user.uid === this.activeChatPartnerUid)) {
        // the user you were chatting with disconnected
        this.setShowChatMainMenu(true);
      }
    },
  },

  methods: {
    changeTabIndex(captionerUser, index) {
      this.activeTabIndex = index;
      this.activeChatPartnerUid = captionerUser.uid;
      this.setShowChatMainMenu(false);
      this.clearNumUnreadMessages(captionerUser.uid);
    },

    callSetShowChatMainMenu(bool) {
      this.setShowChatMainMenu(bool);
      if (bool) {
        // no longer has a conversation open
        this.activeChatPartnerUid = null;
      }
    },

    displayNameChanged() {
      const name = this.displayName;
      if (name) {
        this.updateCurrentUser({ name });
      }
    },
  },
};
</script>

<style lang="scss">
.chat-list {
  background-color: map-get(
    $component-colours,
    side-panel-background-dark-mode
  );
  color: map-get($component-colours, side-panel-font-primary-dark-mode);
  min-width: $side-panel-width-in-rem + rem;
  height: 100%;

  &.light-mode-active {
    background-color: map-get(
      $component-colours,
      side-panel-background-light-mode
    );
    color: map-get($component-colours, side-panel-font-primary-light-mode);
  }

  .conversations {
    list-style: none;
    padding: 0;
    margin-top: 1.2rem;

    .no-chat-partners {
      padding: 1.2rem $side-panel-outside-padding;
      font-size: 1.6rem;
    }

    .conversation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.6rem;
      font-family: $font-secondary;
      font-weight: 500;
      padding: 1.2rem $side-panel-outside-padding;
      text-decoration: none;
      color: map-get($component-colours, side-panel-font-primary-dark-mode);
      background-color: map-get(
        $component-colours,
        side-panel-background-dark-mode
      );
      width: 100%;
      text-align: left;

      .light-mode-active & {
        background-color: map-get(
          $component-colours,
          side-panel-background-light-mode
        );
        color: map-get($component-colours, side-panel-font-primary-light-mode);

        &:hover {
          filter: brightness(95%);
        }
      }

      &:hover {
        filter: brightness(85%);
        color: inherit;
      }

      .left-section {
        display: flex;
        align-items: center;
        width: 100%;

        .p-avatar {
          background-color: map-get($component-colours, chat-avatar);
          color: map-get($component-colours, chat-avatar-text);
          min-width: 4rem;

          .p-avatar-text {
            line-height: 0;
          }
        }

        .user-name {
          margin-left: 1.6rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .user-type {
          font-size: 1.4rem;
          color: map-get($component-colours, side-panel-font-secondary);
          margin-left: 0.8rem;
        }
      }

      .p-badge {
        background: map-get($component-colours, chat-unread-badge);
        font-weight: 400;
        font-size: 1.25rem;
        min-width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
      }
    }
  }

  .edit-display-name {
    margin-top: 2rem;
    padding: 0 $side-panel-outside-padding;

    label {
      display: block;
      font-weight: bold;
      font-size: 2rem;
    }

    img {
      position: absolute;
      height: 3rem;
    }

    input {
      display: block;
      width: 100%;
      max-width: $side-panel-width-in-rem + rem;
      border-color: map-get($component-colours, input-border-light);
      margin-top: 0.8rem;
      font-size: 1.8rem;
      padding: 1.6rem 1.6rem 1.6rem 5rem;
      background-image: url("../../../assets/images/Wave.svg");
      background-repeat: no-repeat;
      background-position: left -4rem center;
      background-origin: content-box;
      background-size: 3rem 3rem;
    }
  }

  .p-tabview {
    height: 100%;

    .p-tabview-nav {
      display: none;
    }

    .p-tabview-panels {
      padding: 0;
      height: 100%;

      .p-tabview-panel {
        height: 100%;
      }
    }
  }
}
</style>
