import { createStore } from "vuex";

export default createStore({
  state: {
    lightModeActive:
      JSON.parse(localStorage.getItem("lightModeActive")) || false,
    sessionDetails: null,
  },
  mutations: {
    setLightModeActive(state, bool) {
      state.lightModeActive = bool;
      localStorage.setItem("lightModeActive", JSON.stringify(bool));
    },
    setSessionDetails(state, details) {
      state.sessionDetails = details;
    },
  },
  actions: {},
  getters: {
    storedUserByEmail: (state) => (email) => {
      return state.storedUsers.find((user) => user.email === email);
    },
  },
});
