export const LINKS = {
  CHAT: "wss://mp-collab-server.habitatlearn.com/chat`",
  MESSENGER_PIGEON: "http://mp.habitatlearn.com/",
  HABITAT_LEARN: "https://www.habitatlearn.com/",
  HABITAT_LEARN_PRODUCTS: "https://www.habitatlearn.com/company/products",
  HABITAT_LEARN_CONTACT: "https://www.habitatlearn.com/company/contact",
  BEEHIVE: "https://beehive.habitatlearn.com/",
  CORAL: "https://coral.habitatlearn.com/",
};

export const FEEDBACK_FORM_STATUSES = {
  INITIAL: "initial",
  SUCCESS: "success",
  ERROR: "error",
};

export const dbConstants = {
  DATABASE_NAME: "database",

  PREFERENCE_TABLE_NAME: "preferences",
  PREFERENCE_TABLE_KEY: "setting",
  PREFERENCE_TABLE_VALUE: "value",
  PREFERENCE_FONT_SIZE: "fontSize",
  PREFERENCE_FONT_COLOUR: "fontColour",
  PREFERENCE_FONT_BOLD: "fontBold",
  PREFERENCE_FONT_FAMILY: "fontFamily",

  SESSION_DATA_TABLE_NAME: "sessionData",
  SESSION_DATA_TABLE_KEY: "key",
  SESSION_DATA_TABLE_VALUE: "value",
  SESSION_DATA_LOGGED_IN_USER: "loggedInUser",
  DEFAULT_USER: "User",

  SERVICE_TYPE_CAPTIONING: "Live Captioning",
  SERVICE_TYPE_NOTETAKING: "Live Note Taking",

  DEFAULT_USERNAME: "Student",
  EDITOR_USER_TYPES: {
    SERVICE_PROVIDER: "service_provider",
    STUDENT: "student",
  },
};
