import { createApp } from "vue";
import App from "./App.vue";
import "./styles/global.scss";
import PrimeVue from "primevue/config";
import "./styles/_theme.scss";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import store from "./store/store";
import OverlayPanel from "primevue/overlaypanel";
import Slider from "primevue/slider";
import RadioButton from "primevue/radiobutton";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import InputSwitch from "primevue/inputswitch";
import BadgeDirective from "primevue/badgedirective";
import Badge from "primevue/badge";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from "primevue/dialog";
import Tooltip from "primevue/tooltip";
import Textarea from "primevue/textarea";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Avatar from "primevue/avatar";
import Rating from "primevue/rating";

import router from "./router";

const app = createApp(App).use(router).use(PrimeVue).use(store);

app.component("OverlayPanel", OverlayPanel);
app.component("Slider", Slider);
app.component("RadioButton", RadioButton);
app.component("Button", Button);
app.component("InputText", InputText);
app.component("Card", Card);
app.component("InputSwitch", InputSwitch);
app.directive("badge", BadgeDirective);
app.directive("tooltip", Tooltip);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Dialog", Dialog);
app.component("Textarea", Textarea);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Avatar", Avatar);
app.component("Badge", Badge);
app.component("Rating", Rating);

app.mount("#app");
