/* eslint-disable */
export function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function generateUID() {
  return Math.random().toString().slice(2, 11);
}

export function convertDateToHoursMinutes(date) {
  return (
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes()
  );
}

export function getRandomElement(list) {
  return list[Math.floor(Math.random() * list.length)];
}

export function getRandomColor() {
  return getRandomElement([
    "#958DF1",
    "#F98181",
    "#FBBC88",
    "#FAF594",
    "#70CFF8",
    "#94FADB",
    "#B9F18D",
  ]);
}
/* eslint-enable */
