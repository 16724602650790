<template>
  <div v-show="visible" class="modal-background" @click="hide">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    showing: Boolean,
  },

  watch: {
    showing: {
      handler(value) {
        this.visible = value;
      },
    },
  },

  data() {
    return {
      visible: false,
    };
  },
  methods: {
    hide(e) {
      if (e.target === e.currentTarget) {
        this.visible = false;
        this.$emit("closed", "");
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_mixins.scss";

.modal-background {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  @include md {
    background: rgba(0, 0, 0, 0.2);
  }
}
</style>
