<template>
  <div
    class="document"
    :class="this.$store.state.lightModeActive ? 'light-mode-active' : ''"
  >
    <Editor
      v-bind:db="db"
      :unreadMessages="unreadMessages"
      :numTotalUnreadMessages="numTotalUnreadMessages"
      :incrementNumUnreadMessages="incrementNumUnreadMessages"
      :clearNumUnreadMessages="clearNumUnreadMessages"
      :users="users"
      :setUsers="setUsers"
      :currentUser="currentUser"
      :setEditor="setEditor"
      :editor="editor"
      :updateCurrentUser="updateCurrentUser"
    />
  </div>
</template>

<script>
import Editor from "../components/Editor.vue";
import ChatIcon from "../assets/images/Chat.svg";
import { generateUID, getRandomColor } from "../assets/helpers";
import { dbConstants } from "../assets/constants";

export default {
  name: "App",

  props: {
    db: Object,
  },

  components: {
    Editor,
  },

  created() {
    document.title = "Messenger Pigeon | " + this.$route.params.document;
  },

  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem("currentUser")) || {
        name: dbConstants.DEFAULT_USERNAME,
        uid: generateUID(),
        color: getRandomColor(),
        type: dbConstants.EDITOR_USER_TYPES.STUDENT,
      },
      users: [],
      numTotalUnreadMessages: 0,
      unreadMessages: new Map(),
      messageLists: new Map(),
      disableTransitions: true,
      ChatIcon,
      editor: null,
    };
  },

  mounted() {
    // For users created before these fields were added
    if (!this.currentUser.type) {
      const type = dbConstants.EDITOR_USER_TYPES.STUDENT;
      this.updateCurrentUser({ type });
    }

    if (!this.currentUser.uid) {
      const uid = generateUID();
      this.updateCurrentUser({ uid });
    }
    localStorage.setItem("currentUser", JSON.stringify(this.currentUser));
  },

  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
    if (this.provider) {
      this.provider.destroy();
    }
  },

  methods: {
    setEditor(editor) {
      this.editor = editor;
    },

    popup() {
      window.open(
        "http://localhost:8080/documents/e.ear",
        "popUpWindow",
        "height=500,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
      );
    },

    updateCurrentUser(attributes) {
      this.currentUser = { ...this.currentUser, ...attributes };
      if (this.editor) {
        this.editor.chain().focus().user(this.currentUser).run();
      }

      localStorage.setItem("currentUser", JSON.stringify(this.currentUser));
    },

    setUsers(users) {
      this.users = users;
    },

    clearNumUnreadMessages(uid) {
      this.numTotalUnreadMessages =
        this.numTotalUnreadMessages - (this.unreadMessages.get(uid) || 0);
      this.unreadMessages.set(uid, 0);
    },

    incrementNumUnreadMessages(uid) {
      this.numTotalUnreadMessages = this.numTotalUnreadMessages + 1;
      if (this.unreadMessages.get(uid)) {
        this.unreadMessages.set(uid, this.unreadMessages.get(uid) + 1);
      } else {
        this.unreadMessages.set(uid, 1);
      }
    },

    addMessageToList(uid, message) {
      const list = this.messageLists.get(uid);
      if (list) {
        this.messageLists.set(uid, list.push(message));
      } else {
        this.messageLists.set(uid, [message]);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_mixins.scss";

.document {
  display: flex;
  flex-direction: row;
  height: 100%;

  &.light-mode-active {
    background-color: map-get(
      $component-colours,
      background-primary-light-mode
    );
  }
}

.chat-mobile {
  .p-overlaypanel-content {
    height: 100%;
  }

  &.show {
    width: $side-panel-width-in-rem + rem;
    min-width: $side-panel-width-in-rem + rem;
  }
}
</style>
