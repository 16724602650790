import Dexie from "dexie";
import { dbConstants } from "./assets/constants";

export class Database extends Dexie {
  constructor() {
    // run the super constructor Dexie(databaseName) to create the IndexedDB
    // database.
    super(dbConstants.DATABASE_NAME);

    this.version(1).stores({
      preferences:
        dbConstants.PREFERENCE_TABLE_KEY +
        ", " +
        dbConstants.PREFERENCE_TABLE_VALUE,
    });

    this.preferences = this.table(dbConstants.PREFERENCE_TABLE_NAME);

    this.version(1).stores({
      sessionData:
        dbConstants.SESSION_DATA_TABLE_KEY +
        ", " +
        dbConstants.SESSION_DATA_TABLE_VALUE,
    });

    this.sessionData = this.table(dbConstants.SESSION_DATA_TABLE_NAME);
  }

  async getPreferences() {
    return await this.preferences.toArray();
  }

  async getPreference(setting) {
    return await this.preferences.get(setting);
  }

  updatePreference(setting, value) {
    return this.preferences.put({
      [dbConstants.PREFERENCE_TABLE_KEY]: setting,
      [dbConstants.PREFERENCE_TABLE_VALUE]: value,
    });
  }

  deletePreference(setting) {
    return this.preferences.delete(setting);
  }

  async getSessionData(key) {
    return await this.sessionData.get(key);
  }

  updateSessionData(key, value) {
    return this.sessionData.put({
      [dbConstants.SESSION_DATA_TABLE_KEY]: key,
      [dbConstants.SESSION_DATA_TABLE_VALUE]: value,
    });
  }

  deleteSessionData(key) {
    return this.sessionData.delete(key);
  }
}