<template>
  <div
    class="settings-panel slid-out-left"
    :class="
      (isOpen ? 'slide-in' : 'slide-out',
      this.$store.state.lightModeActive ? 'light-mode-active' : '')
    "
  >
    <div
      v-if="activeSetting === Setting.MAIN"
      class="settings-main side-panel-tab"
    >
      <SidePanelHeader title="Settings" :clickClose="closeSidePanel" />
      <div class="side-panel-body">
        <div class="settings-list">
          <button disabled>Visuals and Appearance</button>
          <button disabled>Other Preferences</button>
        </div>
        <div class="colour-mode-buttons">
          <button
            class="colour-mode-button"
            @click="this.$store.commit('setLightModeActive', true)"
          >
            <img :src="LightMode" alt="" class="colour-mode-button-image" />
            <img
              v-if="this.$store.state.lightModeActive"
              :src="CheckmarkLight"
              class="selected-check-mark"
            />
            <span>Light mode</span>
          </button>
          <button
            class="colour-mode-button"
            @click="this.$store.commit('setLightModeActive', false)"
          >
            <img :src="DarkMode" alt="" class="colour-mode-button-image" />
            <img
              v-if="!this.$store.state.lightModeActive"
              :src="CheckmarkDark"
              class="selected-check-mark"
            />
            <span>Dark mode</span>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="activeSetting === Setting.VISUALS_AND_APPEARANCE"
      class="settings-visuals"
    >
      <SidePanelHeader />
      Visuals
    </div>
  </div>
</template>

<script>
import SidePanelHeader from "../SidePanelHeader";
import DarkMode from "../../../assets/images/Dark-Mode.svg";
import LightMode from "../../../assets/images/Light-Mode.svg";
import CheckmarkDark from "../../../assets/images/Checkmark-Dark.svg";
import CheckmarkLight from "../../../assets/images/Checkmark-Light.svg";

const Setting = {
  MAIN: 0,
  VISUALS_AND_APPEARANCE: 1,
  OTHER: 2,
};

export default {
  props: {
    isOpen: Boolean,
    closeSidePanel: Function,
  },

  components: {
    SidePanelHeader,
  },

  data() {
    return {
      Setting,
      DarkMode,
      LightMode,
      CheckmarkDark,
      CheckmarkLight,
      activeSetting: Setting.MAIN,
    };
  },

  methods: {
    setLightMode(bool) {
      this.lightModeActive = bool;
      localStorage.setItem("lightModeActive", JSON.stringify(bool));
    },
  },
};
</script>

<style lang="scss">
.settings-panel {
  background-color: map-get(
    $component-colours,
    side-panel-background-dark-mode
  );
  color: map-get($component-colours, side-panel-font-primary-dark-mode);
  min-width: $side-panel-width-in-rem + rem;
  height: 100%;

  &.light-mode-active {
    background-color: map-get(
      $component-colours,
      side-panel-background-light-mode
    );
    color: map-get($component-colours, side-panel-font-primary-light-mode);
  }

  .settings-main {
    .settings-list {
      display: flex;
      flex-direction: column;
      padding: calc(#{$side-panel-outside-padding} / 3) 0;
      border-bottom: 1px solid map-get($component-colours, border-light);

      .light-mode-active & {
        border-bottom: 1px solid map-get($component-colours, border-light-solid);
      }

      button {
        font-size: 1.8rem;
        font-weight: bold;
        text-align: left;
        padding: calc(#{$side-panel-outside-padding} / 1.5)
          $side-panel-outside-padding;
        background: transparent;
        color: inherit;

        &:disabled {
          cursor: initial;
          opacity: 50%;
        }
      }
    }

    .colour-mode-buttons {
      padding: calc(#{$side-panel-outside-padding}/ 1.5)
        $side-panel-outside-padding;
      display: flex;
      flex-direction: row;

      .colour-mode-button {
        position: relative;
        padding: 0;
        display: flex;
        flex-direction: column;
        font-size: 1.4rem;
        background: transparent;

        &:hover {
          filter: none;

          .colour-mode-button-image {
            filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14))
              drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))
              drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
          }
        }

        .selected-check-mark {
          position: absolute;
          bottom: 3.4rem;
          right: 0.8rem;
        }

        &:not(:first-child) {
          margin-left: $side-panel-outside-padding;
        }

        span {
          margin-top: 0.6rem;
          color: map-get($component-colours, side-panel-font-primary-dark-mode);

          .light-mode-active & {
            color: map-get(
              $component-colours,
              side-panel-font-primary-light-mode
            );
          }
        }
      }
    }
  }
}
</style>
