<template>
  <div
    class="welcome-panel"
    :class="this.$store.state.lightModeActive ? 'light-mode-active' : ''"
  >
    <IconButton
      :icon="this.$store.state.lightModeActive ? CloseIcon : CloseIconLight"
      alt="Close menu"
      class="close-button"
      :onclick="clickClose"
      dark
      xsmall
    />
    <h1>Welcome to <span class="product-name">Messenger Pigeon</span></h1>
    <div class="by-habitat-learn">by Habitat Learn</div>
    <div class="welcome-panel-body">
      <div class="conversations">
        <p v-if="captionerUsers.length === 0" class="no-captioners">
          If there is a captioner booked for your session, they will join soon.
        </p>
        <div v-else class="captioners-list">
          <div class="your-caption-corrector bold-label">
            {{
              captionerUsers.length === 1
                ? "Your caption corrector is:"
                : "Your caption correctors are:"
            }}
          </div>
          <div
            v-for="captionerUser in captionerUsers"
            :key="captionerUser.uid"
            class="conversation"
          >
            <div class="left-section">
              <Avatar
                :label="captionerUser.name[0].toUpperCase()"
                size="xlarge"
                shape="circle"
              />
              <div class="user-name">{{ captionerUser.name }}</div>
            </div>
            <span class="user-status secondary-text">Online</span>
          </div>
        </div>
      </div>
      <div class="introduce-yourself">
        <label for="username" class="bold-label">Introduce yourself</label>
        <div class="input-name">
          <InputText
            id="displayName"
            type="username"
            placeholder="Type your name"
            v-model="displayName"
            @input="displayNameChanged"
          >
            /></InputText
          >
          <RoundedSquareButton
            :image="CheckIcon"
            :onClick="setName"
            v-tooltip.top="'Confirm name'"
            accent
            noShadow
          />
        </div>
        <span
          class="welcome-name success-text"
          :class="showWelcomeName ? 'show' : ''"
          >Welcome {{ displayName }}!</span
        >
      </div>
      <div class="session-details">
        <header class="bold-label">Session details</header>
        <div class="session-detail">
          <label class="secondary-text">Name</label>
          {{ this.$store.state.sessionDetails.name }}
        </div>
        <div class="session-detail">
          <label class="secondary-text">Time</label>
          {{
            bookingStartTime && bookingEndTime
              ? bookingStartTime +
                " - " +
                bookingEndTime +
                (bookingTimezone ? " (" + bookingTimezone + ")" : "")
              : ""
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dbConstants } from "../../../assets/constants";
import CheckIcon from "../../../assets/images/Check.svg";
import RoundedSquareButton from "../../RoundedSquareButton";
import IconButton from "../../IconButton";
import CloseIcon from "../../../assets/images/Close.svg";
import CloseIconLight from "../../../assets/images/Close-Light.svg";

export default {
  props: {
    users: Array,
    updateCurrentUser: Function,
    currentUser: Object,
    clickClose: Function,
  },

  components: {
    RoundedSquareButton,
    IconButton,
  },

  data() {
    return {
      CheckIcon,
      CloseIcon,
      CloseIconLight,
      displayName: this.currentUser.name,
      showWelcomeName: false,
    };
  },

  computed: {
    captionerUsers() {
      return this.users.filter(
        (user) => user.type === dbConstants.EDITOR_USER_TYPES.SERVICE_PROVIDER
      );
    },

    bookingStartTime() {
      let time = this.$store.state.sessionDetails.start_time.substr(11, 5);
      // Strip leading 0
      if (time[0] === "0") {
        time = time.substring(1);
      }
      return time;
    },

    bookingEndTime() {
      let time = this.$store.state.sessionDetails.end_time.substr(11, 5);
      // Strip leading 0
      if (time[0] === "0") {
        time = time.substring(1);
      }
      return time;
    },

    bookingTimezone() {
      return this.$store.state.sessionDetails.tz.replaceAll("_", " ");
    },
  },

  methods: {
    setName() {
      const name = this.displayName;
      if (name) {
        this.updateCurrentUser({ name });
        this.showWelcomeName = true;
      }
    },

    displayNameChanged() {
      this.showWelcomeName = false;
    },
  },
};
</script>

<style lang="scss">
$panel-vertical-padding: 3rem;
$max-content-width: 35rem;

.welcome-panel {
  background-color: map-get(
    $component-colours,
    welcome-panel-background-dark-mode
  );
  color: map-get($component-colours, welcome-panel-font-dark-mode);
  padding: $panel-vertical-padding $side-panel-outside-padding;
  font-size: 1.8rem;
  height: 100%;
  position: relative;
  min-width: $side-panel-width-in-rem + rem;
  overflow: auto;

  &.light-mode-active {
    background-color: map-get(
      $component-colours,
      welcome-panel-background-light-mode
    );
    color: map-get($component-colours, welcome-panel-font-light-mode);

    .by-habitat-learn {
      color: map-get($colours, habitat-primary-green);
    }

    .secondary-text {
      color: map-get($component-colours, font-grey-5);
    }

    .success-text {
      color: map-get($colours, habitat-primary-green);
    }
  }

  .secondary-text {
    color: map-get($component-colours, font-grey-2);
  }

  .success-text {
    color: map-get($component-colours, font-colour-success);
  }

  h1 {
    margin: 0;
    margin-right: 3rem;
    font-size: 3.4rem;

    .product-name {
      white-space: nowrap;
    }
  }

  .close-button {
    position: absolute;
    right: 1.6rem;
    top: 2.4rem;
  }

  .welcome-panel-body {
    margin-top: 2.4rem;

    > *:not(:first-child) {
      margin-top: 2rem;
    }

    .no-captioners {
      font-weight: bold;
    }

    .conversations {
      max-width: $max-content-width;

      .conversation {
        padding: 1.2rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-section {
          display: flex;
          align-items: center;

          .p-avatar {
            background-color: map-get($component-colours, chat-avatar);
            color: map-get($component-colours, chat-avatar-text);

            .p-avatar-text {
              line-height: 0;
            }
          }

          .user-name {
            margin-left: 2rem;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 18.5rem;
            font-size: 1.6rem;
          }
        }

        .user-status {
          font-size: 1.6rem;
          margin-left: 0.8rem;
        }
      }
    }

    .introduce-yourself {
      max-width: $max-content-width;

      label {
        display: block;
      }

      .input-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.5rem;

        input {
          width: 100%;
          margin-right: 1.6rem;
          font-size: 1.8rem;
          padding: 1.6rem;
        }

        button {
        }
      }

      .welcome-name {
        display: none;
        margin-top: 1rem;

        &.show {
          display: block;
        }
      }
    }

    .session-details {
      font-size: 1.8rem;

      header {
        padding-top: 0.8rem;
      }

      label {
        display: block;
        font-size: 1.4rem;
      }

      > *:not(:first-child) {
        margin-top: 0.4rem;
      }
    }

    .bold-label {
      font-size: 2rem;
      font-weight: bold;
    }
  }
}
</style>
