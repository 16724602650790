<template>
  <header
    class="side-panel-header"
    :class="this.$store.state.lightModeActive ? 'light-mode-active' : ''"
  >
    <div v-if="clickBack" class="left-wrapper">
      <IconButton
        :icon="
          this.$store.state.lightModeActive
            ? ChevronLeftBlackFatIcon
            : ChevronLeftWhiteFatIcon
        "
        alt="Previous menu"
        @click="clickBack"
        dark
        xsmall
      />
      <h2>{{ this.title }}</h2>
    </div>
    <h2 v-else>{{ this.title }}</h2>
    <IconButton
      :icon="this.$store.state.lightModeActive ? CloseIcon : CloseLightIcon"
      alt="Close menu"
      :onclick="clickClose"
      dark
      xsmall
    />
  </header>
</template>

<script>
import IconButton from "../IconButton";
import ChevronLeftBlackFatIcon from "../../assets/images/Chevron-left-black-fat.svg";
import ChevronLeftWhiteFatIcon from "../../assets/images/Chevron-left-white-fat.svg";
import CloseIcon from "../../assets/images/Close.svg";
import CloseLightIcon from "../../assets/images/Close-Light.svg";

export default {
  props: {
    title: String,
    clickClose: Function,
    clickBack: Function,
  },

  components: {
    IconButton,
  },

  data() {
    return {
      ChevronLeftBlackFatIcon,
      ChevronLeftWhiteFatIcon,
      CloseIcon,
      CloseLightIcon,
    };
  },
};
</script>

<style lang="scss">
.side-panel-header {
  padding: $side-panel-outside-padding;
  padding-right: 1.6rem;
  border-bottom: 1px solid map-get($component-colours, border-light);
  display: flex;
  justify-content: space-between;

  &.light-mode-active {
    border-bottom: 1px solid map-get($component-colours, border-light-solid);
  }

  .left-wrapper {
    display: flex;
    margin-left: -0.8rem;
    overflow: hidden;

    h2 {
      margin-left: 1.6rem;
    }
  }

  h2 {
    font-family: $font-default;
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 0;
    transform: translateY(0.1rem);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .close {
    cursor: pointer;
  }
}
</style>
