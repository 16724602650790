<template>
  <div class="center-form">
    <Card class="uh-oh">
      <template #content>
        <h1>Uh Oh! We can't find that captioning session!</h1>
        <RoundedButton text="Go back" shadow @click="goBack" alternate />
      </template>
    </Card>
  </div>
</template>

<script>
import RoundedButton from "../components/RoundedButton.vue";

export default {
  components: {
    RoundedButton,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.center-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .uh-oh {
    width: 50rem;
    border-radius: 1rem;
    padding: 2rem;

    .p-card-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h1 {
        margin: 0;
        margin-bottom: 2.5rem;
      }
    }
  }
}
</style>